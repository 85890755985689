import { createContext, useReducer, useEffect } from 'react';
import { getUser } from '../utils/getUser';
import axios from 'axios';

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'REGISTER':
      return { user: action.payload.data.user, confirmtoken: action.payload.data.confirmtoken, isLoggedIn: true };
    case 'LOGIN':
      return { user: action.payload.data.user, confirmtoken: action.payload.data.confirmtoken, isLoggedIn: true };
    case 'LOGOUT':
      return { user: null, isLoggedIn: false, confirmtoken: '' };
    case 'UPDATE_USER':
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    confirmtoken: '',
    isLoggedIn: false
  });

  useEffect(() => {
    // Get the user from localStorage, if it exists
    const { data } = JSON.parse(localStorage.getItem('auth')) || {};

    if (data) {
      dispatch({ type: 'REGISTER', isLoggedIn: true, payload: { data } });
    }
  }, []);

  useEffect(() => {
    // Get the user from localStorage, if it exists
    const { data } = JSON.parse(localStorage.getItem('auth')) || {};

    if (data) {
      dispatch({ type: 'LOGIN', isLoggedIn: true, payload: { data } });
    }
  }, []);

  //configuration for axios headers to match up with middleware in ExpressJs
  useEffect(() => {
    const confirmtoken = state.confirmtoken || '';
    // axios.defaults.baseURL = process.env.REACT_APP_API;
    axios.defaults.baseURL = 'https://stripe-app-n.zicnkqgc7ta.eu-de.codeengine.appdomain.cloud/';
    axios.defaults.headers.common['Authorization'] = `Bearer ${confirmtoken}`;
  }, [state.confirmtoken]);

  return <AuthContext.Provider value={{ ...state, dispatch }}>{children}</AuthContext.Provider>;
};
