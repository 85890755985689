export function removeUser() {
  localStorage.removeItem('auth');
}

let savedUser = null;

export function getUser() {
  if (savedUser != null) {
    return savedUser;
  }

  const { data } = JSON.parse(localStorage.getItem('auth')) || {};

  let user = data;
  if (user) {
    savedUser = user;
    return user;
  }
  return false;
}

export function setUser(data) {
  savedUser = data;
  localStorage.setItem('auth', JSON.stringify(data));
}
