import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

// ==============================|| Auth- HOOKS  ||============================== //

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw Error('Auth context must be used inside AuthContextsProvider');
  }
  return context;
};
