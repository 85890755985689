import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { store } from './store';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from './contexts/ConfigContext';
import { Provider as ReduxProvider } from 'react-redux';
import { AuthContextProvider } from './contexts/AuthContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <ReduxProvider store={store}>
      <ConfigProvider>
        <AuthContextProvider>
          <BrowserRouter basename="/">
            <App />
          </BrowserRouter>
        </AuthContextProvider>
      </ConfigProvider>
    </ReduxProvider>
  </>
);
