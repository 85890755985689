import React, { Fragment } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

// material-ui
import { Grid, Typography, Container, useMediaQuery, Link, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Footer = () => {
  const search = useLocation();
  const theme = useTheme();
  const date = new Date();
  const year = date.getFullYear();
 
  const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
  const matchDownSm = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Fragment>
      {' '}
      {matchDownSm && search.pathname === '/login' ? (
        <Container sx={{ textAlign: 'center', p: 1, mt: 0.5, position: 'fixed', bottom: 0 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Typography>
                &copy; Copyright{' '}
                <strong>
                  <span>Heart Rhythm Ireland</span>
                </strong>{' '}
                {year}
              </Typography>{' '}
              <Link component={RouterLink} to={'/privacy-policy'} variant="caption" color="textPrimary">
                Privacy
              </Link>
              &nbsp;&nbsp;
              <Link component={RouterLink} to={'/terms-and-conditions'} variant="caption" color="textPrimary">
                Terms
              </Link>
            </Grid>
          </Grid>
        </Container>
      ) : matchDownMd ? (
        <Container sx={{ textAlign: 'center', p: 1, mt: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Typography>
                &copy; Copyright{' '}
                <strong>
                  <span>Heart Rhythm Ireland</span>
                </strong>{' '}
                {year}
              </Typography>{' '}
              <Link component={RouterLink} to={'/privacy-policy'} variant="caption" color="textPrimary">
                Privacy
              </Link>
              &nbsp;&nbsp;
              <Link component={RouterLink} to={'/terms-and-conditions'} variant="caption" color="textPrimary">
                Terms
              </Link>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Container sx={{ p: 2.5 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: '24px 16px 0px', mt: -4 }}>
            <Typography variant="caption">
              {' '}
              <Typography>
                &copy; Copyright{' '}
                <strong>
                  <span>Heart Rhythm Ireland</span>
                </strong>{' '}
                {year}
              </Typography>{' '}
            </Typography>
            <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
              <Link component={RouterLink} to={'/privacy-policy'} variant="caption" color="textPrimary">
                Privacy
              </Link>
              <Link component={RouterLink} to={'/terms-and-conditions'} variant="caption" color="textPrimary">
                Terms
              </Link>
            </Stack>
          </Stack>
        </Container>
      )}
    </Fragment>
  );
};

export default Footer;
