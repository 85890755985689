import { lazy } from 'react';

// project import
import AuthenticatedLayout from '../layouts/authenticatedLayout';
import Loadable from '../components/Loadable';

//render Dashboard
// const Orders = Loadable(lazy(() => import('../pages/AuthenticatedPages/Orders')));
const IDCard = Loadable(lazy(() => import('../pages/AuthenticatedPages/IDCard')));
const PaymentPlan = Loadable(lazy(() => import('../pages/AuthenticatedPages/Subscription')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/AuthenticatedPages/Account/PrivacyPolicy')));
const PersonalInfo = Loadable(lazy(() => import('../pages/AuthenticatedPages/Account/Personal')));
const PasswordReset = Loadable(lazy(() => import('../pages/AuthenticatedPages/Account/Password')));
const TermsAndConditions = Loadable(lazy(() => import('../pages/AuthenticatedPages/Account/TermsAndConditions')));
const SuccessPage = Loadable(lazy(() => import('../pages/AuthenticatedPages/Success')));
const CancelPage = Loadable(lazy(() => import('../pages/AuthenticatedPages/Cancel')));
const StripeReturnPage = Loadable(lazy(() => import('../pages/AuthenticatedPages/StripeReturn')));

const AuthorizedRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <AuthenticatedLayout />,
      children: [
        {
          path: '/my-id-card',
          element: <IDCard />
        },
        {
          path: '/payment-plan',
          element: <PaymentPlan />
        },

        {
          path: '/my-account/personal-details',
          element: <PersonalInfo />
        },
        {
          path: '/my-account/password-reset',
          element: <PasswordReset />
        },
        {
          path: '/my-account/terms-and-conditions',
          element: <TermsAndConditions />
        },
        {
          path: '/my-account/privacy-policy',
          element: <PrivacyPolicy />
        },
        {
          path: '/stripe/success',
          element: <SuccessPage />
        },
        {
          path: '/stripe/cancel',
          element: <CancelPage />
        },
        {
          path: '/stripe/return',
          element: <StripeReturnPage />
        }
      ]
    }
  ]
};
export default AuthorizedRoutes;
